
import { defineComponent, computed, ref} from "vue";
import VsatRecap from "@/components/networks/vsat/recap/auth/VsatRecap.vue";

import { Field, ErrorMessage,useField} from "vee-validate";
import { useEntGeos } from "@/composables/useEntGeos";

export default defineComponent({
  name: "step-3",
  props: {
     data : {type:Object, default:null},
     currentStep : {type:Boolean, default:false}
  },
  setup(props){
    

    const {getVilleDescription} = useEntGeos();
    const confirmed = ref(false)
    
    return{
      confirmed,
      getVilleDescription
      
    }
  },
  components: {Field, ErrorMessage, VsatRecap},
});
