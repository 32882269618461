import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vsat_form = _resolveComponent("vsat-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vsat_form, {
      icon: _ctx.showStepLabel.icon,
      title: _ctx.showStepLabel.title,
      subtitle: _ctx.showStepLabel.subtitle
    }, null, 8, ["icon", "title", "subtitle"])
  ]))
}