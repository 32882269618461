
import { defineComponent, computed } from "vue";
import VsatForm from "@/components/networks/vsat/form/VsatForm.vue";
// import VsatForm from "@/components/networks/custom/vsat/form/VsatForm.vue";

export default defineComponent({
  name: "step-1",
  setup(props){

    // Affichage du nom du tableau
    const showStepLabel = computed(() => 
    {
      let subtitle = "Veuillez préciser les détails du réseau"
      let title = "Réseau Vsat",
          icon = "las la-wifi fs-1";
      return {title, subtitle, icon}
    })
        
    return{
      showStepLabel,
    }
  },
  components: {
    VsatForm
  },
});
